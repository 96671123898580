


























































































































































































































































import Vue from "vue";
import $ from "jquery";

import { IMember, IMemberLicense, IPlan } from "../types";

import vSelect from "vue-select";
Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";
import InviteMemberModal from "../modals/InviteMemberModal.vue";
import EditMemberModal from "../modals/EditMemberModal.vue";
import RemoveMemberModal from "../modals/RemoveMemberModal.vue";

export default Vue.extend({
  components: { InviteMemberModal, EditMemberModal, RemoveMemberModal },
  data: function () {
    return {
      tableHeight: 0,
      userEmailFrequency: "never",
      user2FAMethod: "email",
      checked: false,
      searchText: "",
      roleFilter: "all",
      selectedMemberId: 0,
      oldMemberData: {} as IMember,
      previewImage: null as string | ArrayBuffer | null,
      companyId: "",
      members: [] as Array<IMember>,
      pendingInvites: [] as Array<{ id: number; email: string; role: string }>,
      plans: [] as Array<IPlan>,
      canEdit: false,
      companyName: "",
      showInviteMemberModal: false,
      showEditMemberModal: false,
      showRemoveMemberModal: false,
      licenseOptions: [
        {
          id: 1,
          label: "SignAgent Online",
          licenseType: "O",
          subscriptionItem: {
            planPrice: {
              pricingPlan: {
                id: 13,
                name: "SignAgent Online",
              },
            },
          },
        },
      ],
    };
  },
  mounted: function () {
    window.addEventListener("resize", this.setTableHeight);
    this.setTableHeight();

    this.companyId = this.$route.params.id;

    this.fetchPendingInvites();
    this.fetchData();
  },
  methods: {
    inviteMember: function () {
      this.showInviteMemberModal = true;
    },
    editMember: function (memberId: number) {
      this.selectedMemberId = memberId;
      this.showEditMemberModal = true;
    },
    onEditMemberModalClosed: function () {
      this.showEditMemberModal = false;
      this.fetchData();
    },
    removeMember: function (memberId: number) {
      this.selectedMemberId = memberId;
      this.showRemoveMemberModal = true;
    },
    onRemoveMemberModalClosed: function () {
      this.showRemoveMemberModal = false;
      this.fetchData();
    },
    userHasPendingInvite: function (email: string) {
      return this.pendingInvites.some((invite) => {
        return invite.email === email;
      });
    },
    getInviteIdForMember: function (email: string) {
      return this.pendingInvites.find((invite) => {
        return invite.email === email;
      })?.id;
    },
    fetchData: function () {
      // load members
      const query = JSON.stringify({
        query: `query get_members {
      company (id: ${this.companyId})
        {
          canEdit
          name
          userProfiles {
            id: userId
            profileId: contentObjectId
            firstName
            lastName
            email
            phone
            image
            role
            twoFAMethod
            emailFrequency
            companyLicenses {
              effectiveEndDate
              subscriptionItem {
                planPrice {
                  pricingPlan {
                    name
                  }
                }
              }
            }
          }
        }
      }`,
      });

      this.members = [];

      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      })
        .then((result) => result.json())
        .then((data) => {
          this.canEdit = data.data.company.canEdit;
          this.companyName = data.data.company.name;
          data.data.company.userProfiles.forEach((userProfile: IMember) => {
            this.members.push(userProfile);
          });
        });
    },
    fetchPendingInvites: function () {
      // load pending invites
      const query = JSON.stringify({
        query: `query get_pending_invites {
              pendingInvites (pending: true, company: ${this.companyId}) {
                edges {
                  node {
                    id: contentObjectId
                    email
                    role
                  }
                }
              }
          }`,
      });

      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      })
        .then((result) => result.json())
        .then((data) => {
          console.log(data);
          this.pendingInvites = data.data.pendingInvites.edges.map(
            (invite: { node: { id: number; email: string; role: string } }) => {
              return {
                id: invite.node.id,
                email: invite.node.email,
                role: invite.node.role,
              };
            }
          );
        });
    },
    // showInvitePeopleModal: function () {
    //   this.inviteToPlan = this.$store.getters.plans[0].subscriptionId;
    //   $("#invitePeopleModal").modal("show");
    // },
    setTableHeight: function () {
      // 220 is just a number that I picked and it seems to work
      this.tableHeight = window.innerHeight - 220;
    },
    showMemberSettings: function (memberId: number) {
      this.previewImage = null;
      this.selectedMemberId = memberId;
      this.oldMemberData = JSON.parse(JSON.stringify(this.selectedMember));
      $("#settingsModal").modal({ show: true });
    },
    roleName: function (role: string) {
      switch (role.toLowerCase()) {
        case "billing_owner":
          return "Billing Owner";
        case "admin":
          return "Administrator";
        case "member":
          return "Member";
        default:
          return "Unknown: " + role;
      }
    },
    get_license_type: function (license_type: string) {
      let type = "";
      if (license_type === "O") {
        type = "ONLINE";
      } else {
        type = "WEB";
      }
      return type;
    },
    cancelInvite: function (inviteId: number) {
      const query = JSON.stringify({
        query: `mutation delete_invite {
          deletePendingInvite (input: {id: ${inviteId}}) {
            success
          }
        }`,
      });

      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          console.log(data.data.deletePendingInvite);
          if (data.data.deletePendingInvite.success) {
            this.fetchPendingInvites();
          } else {
            console.log("Error deleting invite");
          }
        });
    },
  },
  computed: {
    selectedMember: function (): IMember {
      for (let i = 0; i < this.members.length; i++) {
        if (this.members[i].id === this.selectedMemberId) {
          return this.members[i];
        }
      }

      return {
        id: 0,
        profileId: 0,
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        image: "",
        role: "",
        twoFAMethod: "",
        emailFrequency: "",
        companyLicenses: [],
        agreesToTerms: false,
        isBuilder: false,
      };
    },
    filteredMembers: function (): Array<IMember> {
      if (this.searchText === "" && this.roleFilter === "all") {
        return this.members;
      } else {
        let roleSet = [];

        if (this.roleFilter === "all") {
          roleSet = this.members;
        } else {
          roleSet = this.members.filter((member: IMember) => {
            return member.role.toLowerCase() === this.roleFilter.toLowerCase();
          });
        }
        return roleSet.filter((member: IMember) => {
          return (
            member.firstName
              .toLowerCase()
              .startsWith(this.searchText.toLowerCase()) ||
            member.lastName
              .toLowerCase()
              .startsWith(this.searchText.toLowerCase()) ||
            member.email.toLowerCase().startsWith(this.searchText.toLowerCase())
          );
        });
      }
    },
    roleCount: function () {
      return (roleName: string) => {
        let recs = [];
        if (this.searchText !== "") {
          recs = this.filteredMembers.filter((member) => {
            return member.role.toLowerCase() === roleName.toLowerCase();
          });
        } else {
          recs = this.members.filter((member: IMember) => {
            return member.role.toLowerCase() === roleName.toLowerCase();
          });
        }
        return recs.length;
      };
    },
    licenses: {
      get(): IMemberLicense[] {
        return this.selectedMember.companyLicenses;
      },
      set(value: Array<{ id: number; name: string }>) {
        console.log("setting licenses");
        console.log(value);
        // this.selectedMember.companyLicenses = [];
        // value.forEach((license) => {
        //   this.selectedMember.companyLicenses.push({
        //     member: this.selectedMemberId,
        //     type: "O",
        //     subscriptionItem: {
        //       planPrice: {
        //         pricingPlan: {
        //           id: license.id,
        //           name: license.label,
        //         },
        //       },
        //     },
        //   });
        // });
      },
    },
    mappedLicenses: function (): Array<{ id: number; label: string }> {
      const result = this.licenseOptions.map((option) => {
        return {
          id: option.subscriptionItem.planPrice.pricingPlan.id,
          label: option.subscriptionItem.planPrice.pricingPlan.name,
        };
      });
      return result;
    },
  },
});
