import Snap from "snapsvg-cjs-ts";
import {
  makeFloat,
  normalCaseToUnderscore,
  reverseTransformString,
} from "./include/Utils";
import {
  IAlignment,
  IAlignmentTarget,
  IField,
  iRGB,
  iSignChild,
} from "./types";
import { SignDesignerState } from "./store";

const inchToPoints = 72.0;
const feetToPoints = 864;
const yardToPoints = 2592;
const picaToPoints = 12.0;
const mmToPoints = 2.83465;
const cmToPoints = mmToPoints * 10;
const meterToPoints = cmToPoints * 10;
/**
 * Returns a Version 4 UUID string
 */
export function uuidv4(): string {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

/**
 * return a css string with a hash character prepended (if needed) so that it can be used as a backgroundColor
 * @param {string} color - a css hex color string (i.e. "#0176bc" or "0176bc")
 * @returns {string} the hex string with the hash character prepended
 */
export function hexString(color: string): string {
  if (color) {
    if (color.charAt(0) !== "#") {
      color = "#" + color;
    }
  }
  return color;
}

/* eslint-disable-next-line */
export function isNumeric(n: any): boolean {
  return !isNaN(Number(n)) && isFinite(n);
}

export function decimalToHex(decimal: number): string {
  const hex = decimal.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

export function rgbToHex(r: number, g: number, b: number): string {
  return "#" + decimalToHex(r) + decimalToHex(g) + decimalToHex(b);
}

/**
 * converts a hexadecimal color code to the corresponding RGB value
 * @param {string} hex - the hexidecimal color code to convert
 * @returns {iRGB} - an RGB value
 */
export function hexToRGB(hex: string): iRGB {
  // Remove the '#' character if it exists
  hex = hex.replace("#", "");

  // Convert the hexadecimal string to an integer
  const num = parseInt(hex, 16);

  const rgb: iRGB = {
    r: (num >> 16) & 255,
    g: (num >> 8) & 255,
    b: num & 255,
  };
  // Extract the red, green, and blue components

  // Return the RGB value as a string
  return rgb;
}

export function rgbStringToHex(rgbString: string): string {
  // Get the comma-separated RGB values as an array
  const startPos = rgbString.indexOf("(") + 1;
  const rgbValues = rgbString
    .substring(startPos, rgbString.length - 1)
    .split(",");

  // Convert each RGB value to its hex equivalent
  const hexValues = rgbValues.map((value: string) => {
    const hex = Number(value).toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  });

  // Concatenate the hex values to form the final hex code
  const hexCode = "#" + hexValues.join("");

  return hexCode;
}

export function getConversionForUnit(unit: string): number {
  let retval = 0;

  switch (unit) {
    case '"':
    case "in":
    case "inch":
      retval = inchToPoints;
      break;

    case "'":
    case "ft":
    case "feet":
    case "foot":
      retval = feetToPoints;
      break;

    case "yard":
    case "yd":
      retval = yardToPoints;
      break;

    case "mm":
    case "millimetre":
    case "millimeter":
      retval = mmToPoints;
      break;

    case "cm":
    case "centimetre":
    case "centimeter":
      retval = cmToPoints;
      break;

    case "metre":
    case "meter":
      retval = meterToPoints;
      break;

    case "pi":
    case "pica":
      retval = picaToPoints;
      break;

    case "pt":
    case "point":
    case "points":
    case "px":
      retval = 1;
      break;

    default:
      retval = 1;
      console.log(
        `utilities1: unit of measure ${unit} not recognized, defaulting to points`
      );
      break;
  }

  return retval;
}
export function convertUnitToPoints(value: number, unit: string): number {
  let retval = 0;

  switch (unit) {
    case '"':
    case "in":
    case "inch":
      retval = value * inchToPoints;
      break;

    case "'":
    case "ft":
    case "feet":
    case "foot":
      retval = value * feetToPoints;
      break;

    case "yard":
    case "yd":
      retval = value * yardToPoints;
      break;

    case "mm":
    case "millimetre":
    case "millimeter":
      retval = value * mmToPoints;
      break;

    case "cm":
    case "centimetre":
    case "centimeter":
      retval = value * cmToPoints;
      break;

    case "metre":
    case "meter":
      retval = value * meterToPoints;
      break;

    case "pi":
    case "pica":
      retval = value * picaToPoints;
      break;

    case "pt":
    case "point":
    case "points":
    case "px":
      retval = value;
      break;

    default:
      retval = value;
      console.log(
        `utilities2: unit of measure ${unit} not recognized, defaulting to points`
      );
      break;
  }

  return retval;
}

export function convertPointsToUnit(value: number, unit: string): number {
  let retval = 0;

  switch (unit) {
    case '"':
    case "in":
    case "inch":
      retval = value / inchToPoints;
      break;

    case "'":
    case "ft":
    case "feet":
    case "foot":
      retval = value / feetToPoints;
      break;

    case "yard":
    case "yd":
      retval = value / yardToPoints;
      break;

    case "mm":
    case "millimetre":
    case "millimeter":
      retval = value / mmToPoints;
      break;

    case "cm":
    case "centimetre":
    case "centimeter":
      retval = value / cmToPoints;
      break;

    case "metre":
    case "meter":
      retval = value / meterToPoints;
      break;

    case "pi":
    case "pica":
      retval = value / picaToPoints;
      break;

    case "pt":
    case "point":
    case "points":
    case "px":
      retval = value;
      break;

    default:
      retval = value;
      console.log(
        `utilities3: unit of measure ${unit} not recognized, defaulting to points`
      );
      break;
  }

  return retval;
}

/**
 * remove the selection handles
 * @param {Snap.Element} canvas - the Snap SVG canvas
 */
export function removeSelectionHandles(canvas: Snap.Element): void {
  let selection = canvas.selectAll(".selection-handles");
  selection.forEach(function (item: Snap.Element) {
    item.remove();
  });

  // remove the repeat handles if they are showing
  selection = canvas.selectAll(".repeat-handles");
  selection.forEach(function (item: Snap.Element) {
    item.remove();
  });
}

/**
 * calculate the height of the repeat element from child fields
 * @param {any} thisFromVue - the vue this variable.
 * @param {string} repeatId - the id of the repeat
 *
 */
// eslint-disable-next-line
export function calculateRepeatSize(thisFromVue: any, repeatId: string): void {
  const repeat = JSON.parse(
    JSON.stringify(thisFromVue.getters.repeatById(repeatId))
  );

  let rowHeight = 0;
  let rowWidth = 0;

  thisFromVue.getters.fields.forEach((field: IField) => {
    if (field.belongsToRepeatId === repeatId) {
      if (field.height > rowHeight) {
        rowHeight = field.height;
      }
      if (field.x + field.width > rowWidth) {
        // if the row Width is 0 then this is the first time through.
        // we don't need to add the x we only need the width
        if (rowWidth === 0) {
          rowWidth = field.width;
        } else {
          rowWidth = field.x + field.width;
        }
      }
    }
  });

  // repeat height hasn't been set yet so set it.
  if (repeat.height === 0) {
    repeat.height = rowHeight;
    repeat.width = rowWidth;
  }
  repeat.steps = Math.floor(repeat.height / rowHeight);
  repeat.offset = rowHeight;
  thisFromVue.commit("updateRepeat", repeat);
}

export function getNoFillNoStrokeByElementId(
  elementId: string,
  canvas: Snap.Element
): Snap.Element {
  const el = canvas.select("[sa-data-id='" + elementId + "']");
  const parent = el.parent();
  return parent.select("rect");
}

export function getAncestorById(
  svgId: string,
  id: string,
  canvas: Snap.Element
): Snap.Element | null {
  const el = canvas.select("[sa-data-id='" + svgId + "']");
  if (el) {
    const parent = el.parent();
    if (parent) {
      if (parent.attr("id")) {
        if (parent.attr("id").substring(0, id.trim().length) === id) {
          return parent;
        } else if (parent.parent()) {
          return getAncestorById(parent.attr("sa-data-id"), id, canvas);
        }
      } else {
        return getAncestorById(parent.attr("sa-data-id"), id, canvas);
      }
    }
  }
  return null;
}

export function buildAlignmentString(
  sourceAlignment: string,
  targetAlignment: string,
  targetField: string,
  offset: string
): string {
  return (
    "align_" +
    sourceAlignment +
    "_to_" +
    targetAlignment +
    ": " +
    targetField +
    " " +
    offset
  );
}

export function fieldNameFromAlignmentString(alignmentString: string): string {
  let commandRight = alignmentString.substring(
    alignmentString.indexOf(":") + 1
  );
  commandRight = commandRight.trim();
  const attributes = commandRight.split(" ");
  const fieldName = attributes[0];
  return fieldName;
}

export function updateSVGDataName(
  id: string,
  type: string,
  oldValue: string,
  newValue: string,
  canvas: Snap.Element
): void {
  switch (type.toLowerCase()) {
    case "text":
    case "char_x":
    case "char":
    case "choice":
    case "trans":
    case "icon":
    case "icon_t": {
      const rect = getNoFillNoStrokeByElementId(id, canvas);
      let dataName = rect.attr("data-name");
      if (dataName) {
        dataName = reverseTransformString(dataName);
      } else {
        dataName = reverseTransformString(rect.attr("id"));
      }
      const commands = dataName.split(",");
      if (oldValue !== "") {
        if (oldValue.includes(":")) {
          const command = oldValue.substring(0, oldValue.indexOf(":"));
          let commandFound = false;
          for (let i = 0; i < commands.length; i++) {
            if (
              commands[i]
                .substring(0, commands[i].indexOf(":"))
                .trim()
                .toLowerCase() === command.trim().toLowerCase()
            ) {
              commandFound = true;
              if (command.substring(0, 5) === "align") {
                if (
                  fieldNameFromAlignmentString(commands[i]) ===
                  fieldNameFromAlignmentString(oldValue)
                ) {
                  commands[i] = newValue;
                }
              } else {
                commands[i] = newValue;
              }
            }
          }
          if (!commandFound) {
            // the command wasn't found in the data-name attribute so add it
            commands.push(command + ": " + newValue);
            if (command.substring(0, 7) === "leading") {
              // removes duplicate leading in initial update
              commands[2] = commands[2].substring(9);
            }
          }
        } else {
          for (let i = 0; i < commands.length; i++) {
            if (
              commands[i].trim().toLowerCase() === oldValue.trim().toLowerCase()
            ) {
              commands[i] = newValue;
            }
          }
        }
      } else {
        commands.push(newValue);
      }

      //.filter((x) => x) removes all "falsy" values (nulls, undefineds, empty strings etc).
      // This way we won't get a comma for empty commands
      rect.attr({ "data-name": commands.filter((x) => x).join(",") });
      rect.attr({
        id: normalCaseToUnderscore(commands.filter((x) => x).join(" ")),
      });
      break;
    }
    case "color_x":
    case "color":
    case "color_t":
      // color fields shouldn't have any alignment commands
      break;
    default: {
      const el = canvas.select("[sa-data-id='" + id + "']");

      let commands = [] as Array<string>;
      if (el.attr("data-name")) {
        commands = el.attr("data-name").split(",");
      } else {
        commands[0] = newValue;
      }

      for (let i = 0; i < commands.length; i++) {
        if (
          commands[i].trim().toLowerCase() === oldValue.trim().toLowerCase()
        ) {
          commands[i] = newValue;
        }
      }

      //.filter((x) => x) removes all "falsy" values (nulls, undefineds, empty strings etc).
      // This way we won't get a comma for empty commands
      el.attr({ "data-name": commands.filter((x) => x).join(",") });
      break;
    }
  }
}

export function updateSVGAttribute(
  id: string,
  type: string,
  attributeName: string,
  newValue: string,
  canvas: Snap.Element
): void {
  switch (type.toLowerCase()) {
    case "repeat": {
      let rect = null as Snap.Element | null;
      if (attributeName.toLowerCase() === "offset") {
        const el = canvas.select("[sa-data-id='" + id + "']");
        rect = el.parent().parent().select("g#height");
        if (rect) {
          const columnRect = rect.select("rect");
          columnRect.attr({ height: newValue });
        }
      } else {
        rect = getAncestorById(id, "column", canvas);
        if (rect) {
          const columnRect = rect.select("rect");
          columnRect.attr({ [attributeName]: newValue });
        }
      }
      break;
    }
    case "text":
    case "char_x":
    case "char":
    case "choice":
    case "trans": {
      const rect = getNoFillNoStrokeByElementId(id, canvas);
      rect.attr({ [attributeName]: parseFloat(newValue) });
      break;
    }
    case "color_x":
    case "color":
    case "color_t": {
      const el = canvas.select("[sa-data-id='" + id + "']");
      el.attr({ [attributeName]: parseFloat(newValue) });

      break;
    }
    case "icon":
    case "icon_t": {
      const el = canvas.select("[sa-data-id='" + id + "']");
      el.attr({ [attributeName]: parseFloat(newValue) });

      break;
    }
    case "date":
      break;
    case "integer":
      break;
  }
}

export function getCommandByName(
  element: iSignChild,
  name: string,
  canvas: Snap.Element
): string {
  let command = "";

  const el = canvas.select("[sa-data-id='" + element.id + "']");

  let commands = [] as Array<string>;
  if (el.attr("data-name")) {
    commands = el.attr("data-name").split(",");
  }

  for (let i = 0; i < commands.length; i++) {
    const [commandName] = commands[i].split(":");
    if (commandName.trim().toLowerCase() === name.trim().toLowerCase()) {
      command = commands[i];
    }
  }
  return command;
}

export function buildRepeatAlignmentStrings(
  alignment: IAlignment,
  previousRowAlignments: Array<IAlignmentTarget>
): string[] {
  let alignString = "";
  const alignmentStrings = [] as Array<string>;
  let oldSourceAlign = previousRowAlignments[0].sourceAlignment;
  let oldTargetAlign = previousRowAlignments[0].targetAlignment;
  //previousRowAlignments.forEach((alignment) => {
  for (let i = 0; i < previousRowAlignments.length; i++) {
    const target = previousRowAlignments[i];
    if (oldSourceAlign !== target.sourceAlignment) {
      alignString +=
        "align_" +
        oldSourceAlign +
        "_to_" +
        oldTargetAlign +
        " " +
        normalCaseToUnderscore(target.field);
      alignmentStrings.push(alignString);
      oldSourceAlign = target.sourceAlignment;
      alignString =
        normalCaseToUnderscore(alignment.sourceField) +
        " " +
        target.offset +
        " ";
    } else if (oldTargetAlign !== target.targetAlignment) {
      alignString +=
        "align_" +
        oldSourceAlign +
        "_to_" +
        oldTargetAlign +
        " " +
        normalCaseToUnderscore(target.field);
      alignmentStrings.push(alignString);
      oldTargetAlign = target.targetAlignment;
      alignString =
        normalCaseToUnderscore(alignment.sourceField) +
        " " +
        target.offset +
        " ";
    } else {
      alignString +=
        normalCaseToUnderscore(alignment.sourceField) +
        " " +
        target.offset +
        " ";
    }
  } //);
  alignString +=
    "align_" +
    oldSourceAlign +
    "_to_" +
    oldTargetAlign +
    " " +
    normalCaseToUnderscore(
      previousRowAlignments[previousRowAlignments.length - 1].field
    );
  alignmentStrings.push(alignString);
  return alignmentStrings;
}

export function base64ToArrayBuffer(base64: string): ArrayBuffer {
  const binaryString = window.atob(base64);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}

export function alignText(alignment: string, el: Snap.Element): void {
  const rectWidth: number = parseFloat(
    el.parent().select("rect").attr("width")
  );
  const textWidth = el.getBBox().width;
  const rectX: number = parseFloat(el.parent().select("rect").attr("x"));

  // get the translation string
  const transString = el.transform();
  // strip out the y coordinate
  const yTrans = transString.local.split(",")[1];

  if (alignment === "left") {
    const x = rectX;

    el.transform("t " + x + ", " + yTrans);
  }

  if (alignment === "center") {
    const x = rectX + (rectWidth / 2 - textWidth / 2);

    el.transform("t " + x + ", " + yTrans);
  }

  if (alignment === "right") {
    let x = rectX;

    x = rectWidth - textWidth + x;

    el.transform("t " + x + ", " + yTrans);
  }
}

/**
 * checks if the feature is available for the user
 * @param {any} thisFromVue - the vue this variable.
 * @param {string} featureName - the name of the feature
 *
 */
// eslint-disable-next-line
export function featureAvailable(thisFromVue: any, featureName: string): boolean {
  let userFound = false;
  const domain: string[] = [];
  for (let i = 0; i < thisFromVue.$store.getters.featureAccess.length; i++) {
    const theFeature = thisFromVue.$store.getters.featureAccess[i];
    if (theFeature.feature === featureName && theFeature.user === "all") {
      return true;
    } else if (
      theFeature.feature === featureName &&
      theFeature.user === "none"
    ) {
      return false;
    } else if (
      theFeature.feature === featureName &&
      theFeature.user[0] === "*"
    ) {
      domain.push(theFeature.user.slice(2));
    }
  }
  for (let i = 0; i < thisFromVue.$store.getters.featureAccess.length; i++) {
    const theFeature = thisFromVue.$store.getters.featureAccess[i];
    if (
      theFeature.feature === featureName &&
      theFeature.user === thisFromVue.$store.getters.user.email
    ) {
      userFound = true;
    } else if (
      theFeature.feature === featureName &&
      (domain.includes(theFeature.user.split("@")[1]) ||
        domain.includes(thisFromVue.$store.getters.user.email.split("@")[1]))
    ) {
      userFound = true;
    }
  }
  return userFound;
}

// export function saveState(
//   state: SignDesignerState,
//   svgState: string,
//   mutationName: string
// ): void {
//   const newChange = {
//     type: mutationName,
//     vueState: $.extend(true, {}, state),
//     svgState: svgState,
//   };
//   state.history.push(newChange);
// }

// eslint-disable-next-line
export function saveState(state: SignDesignerState, description: string, undoMutation: Array<{mutationName: string, undoValue: any}>, redoMutation: Array<{mutationName: string, redoValue: any}>) {
  const newChange = {
    description: description,
    undoMutation: undoMutation,
    redoMutation: redoMutation,
  };

  if (!state.processingUndo) {
    state.history.push(newChange);
  }
}

/**
 * test if an object is empty
 * @param {any} obj - the object to test
 * @returns {boolean} - True if object is empty
 */
// eslint-disable-next-line
export function isEmpty (obj: any): boolean {
  // console.debug(`isEmpty: ${sequenceNum++}`);
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
}

// eslint-disable-next-line
export function clearSignTemplate(thisFromVue: any, clearSignData = false) {
  thisFromVue.$store.commit("setFiles", []);
  thisFromVue.$store.commit("setParts", []);
  thisFromVue.$store.commit("setSignMaterials", []);
  thisFromVue.$store.commit("setSignProcesses", []);
  thisFromVue.$store.commit("setRepeats", []);
  thisFromVue.$store.commit("setAlignments", []);
  thisFromVue.$store.commit("setSelectedElementIds", []);
  thisFromVue.$store.commit("deselectAllFields");
  thisFromVue.$store.commit("deselectAllUsedFields");
  thisFromVue.$store.commit("setColors", []);

  if (clearSignData) {
    const signData = JSON.parse(
      JSON.stringify(thisFromVue.$store.getters.signData)
    );
    signData.sides[0].children = [];
    thisFromVue.$store.commit("setSignData", signData);
  }

  if (!isEmpty(thisFromVue.$store.getters.canvas)) {
    thisFromVue.$store.getters.canvas.select("*")?.remove();
    thisFromVue.$store.commit("setCanvas", {});
  }
}

// eslint-disable-next-line
export function arraysEqual(array1: any, array2: any): boolean {
  // If the arrays are of different lengths, they are not equal
  if (array1.length !== array2.length) {
    return false;
  }

  // Iterate over each element of the arrays and compare them
  for (let i = 0; i < array1.length; i++) {
    if (array1[i] !== array2[i]) {
      return false;
    }
  }

  // If all elements are equal, the arrays are equal
  return true;
}

// eslint-disable-next-line
export function findRepeatByElementIds(thisFromVue: any, elementIds: Array<string>): boolean {
  let found = false;

  for (let i = 0; i < thisFromVue.$store.getters.repeats.length; i++) {
    const repeat = thisFromVue.$store.getters.repeats[i];
    if (arraysEqual(repeat.elementIds, elementIds)) {
      found = true;
    }
  }

  return found;
}

/**
 * Find an element in the iSignChild tree by the element's id
 * @param {iSignChild}  element - the element to start looking in
 * @param {string} id - the id to find
 * @returns {iSignChild | null} The found element or null
 */
export function findElementById(
  element: iSignChild,
  id: string
): iSignChild | null {
  if (element.id == id) {
    return element;
  } else if (element.children != null) {
    let result = null;
    for (let i = 0; result == null && i < element.children.length; i++) {
      result = findElementById(element.children[i], id);
    }
    return result;
  }
  return null;
}
/**
 * get the field definition from the child elements
 * @param {Snap.Element} element - the element to search
 * @param {IField} signChild - the field we are working with
 * @returns {IField} the field definition
 */
export function getFieldDefFromChildren(
  element: Snap.Element,
  signChild: IField
): IField {
  // console.debug(`getFieldDefFromChildren: ${sequenceNum++}`);
  let id = "";
  let name = "";
  let type = "";
  let width = 0;
  let height = 0;
  let x = 0;
  let y = 0;
  let hasPlaceholderImage = false;
  let placeholderImageUrl = "";
  const elementIds = [] as Array<string>;

  // eslint-disable-next-line
  element.children().forEach((child: any) => {
    if (child.type === "rect") {
      if (
        child.attr("data-name") !== "spacer" &&
        child.attr("id") !== "spacer"
      ) {
        width = makeFloat(child.attr("width"));
        height = makeFloat(child.attr("height"));
        x = makeFloat(child.attr("x"));
        y = makeFloat(child.attr("y"));
        if (
          (child.attr("stroke") == null || child.attr("stroke") === "none") &&
          (child.attr("fill") == null || child.attr("fill") === "none")
        ) {
          if (
            signChild.type.toLowerCase() === "icon" ||
            signChild.type.toLowerCase() === "icon_t" ||
            signChild.type.toLowerCase() === "icon_x"
          ) {
            id = child.id;
            if (child.attr("display") !== "none") {
              elementIds.push(child.id);
            }
          }
        } else {
          id = child.id;
          if (child.attr("display") !== "none") {
            elementIds.push(child.id);
          }
        }
      }
    } else if (child.type != "#text") {
      if (
        signChild.type.toLowerCase() === "icon" ||
        signChild.type.toLowerCase() === "icon_t" ||
        signChild.type.toLowerCase() === "icon_x"
      ) {
        //don't add the placeholder image or hidden elements to the element Ids
        if (child.type !== "image" && child.attr("display") !== "none") {
          //this is a visual field
          id = child.id;
          name = child.name;
          type = child.type;
          //make a clone of the child element so we don't get vuex mutation errors
          const el1 = $.extend(true, {}, child);
          const bb = el1.getBBox();
          width = bb.width;
          height = bb.height;
          x = bb.x;
          y = bb.y;
          elementIds.push(child.id);
        }
        // if the image is a placeholder-image then set the placeholderImageUrl and hasPlaceholderImage fields
        if (child.type === "image" && child.hasClass("placeholder-image")) {
          placeholderImageUrl = child.attr("href");
          hasPlaceholderImage = true;
        }
      } else {
        id = child.id;
        name = child.name;
        type = child.type;
        if (child.attr("display") !== "none") {
          elementIds.push(child.id);
        }
      }
    }
  });
  return {
    id: id,
    isSelected: false,
    isUsed: true,
    name: name,
    type: type,
    x: x,
    y: y,
    width: width,
    height: height,
    capHeight: NaN,
    leading: NaN,
    lines: NaN,
    letterSpacing: "",
    horizontalAlignment: "left",
    verticalAlignment: "top",
    hasPlaceholderImage: hasPlaceholderImage,
    placeholderImageUrl: placeholderImageUrl,
    belongsToRepeatId: null,
    elementIds: elementIds,
  };
}

/**
 * draw repeat elements
 */
// eslint-disable-next-line
export function drawRepeatElements(thisFromVue: any): void {
  if (thisFromVue.$store.getters.selectedRepeat) {
    //get all the selected elements
    thisFromVue.$store.getters.selectedRepeat.clonedElementIds.forEach(
      (elementId: string) => {
        const el = thisFromVue.$store.getters.canvas.select(
          "[sa-data-id='" + elementId + "']"
        );
        el.remove();
      }
    );

    thisFromVue.$store.commit("deleteSelectedRepeatClonedElementIds");

    let topX = -1;
    let topY = -1;
    let bottomX = -1;
    let bottomY = -1;

    thisFromVue.$store.getters.selectedRepeat.elementIds.forEach(
      (elementId: string) => {
        const el = thisFromVue.$store.getters.canvas.select(
          "[sa-data-id='" + elementId + "']"
        );
        //make a clone of the child element so we don't get vuex mutation errors
        const el1 = $.extend(true, {}, el);
        const bb = el1.getBBox();

        if (topX < 0) {
          topX = bb.x;
        } else {
          topX = topX < bb.x ? topX : bb.x;
        }
        if (topY < 0) {
          topY = bb.y;
        } else {
          topY = topY < bb.y ? topY : bb.y;
        }
        bottomX = bottomX > bb.x2 ? bottomX : bb.x2;
        bottomY = bottomY > bb.y2 ? bottomY : bb.y2;
      }
    );

    // //calculate the height by finding the tallest child in the selected component
    // let height = bottomY - topY;

    for (
      let i = 0;
      i < thisFromVue.$store.getters.selectedRepeat.steps - 1;
      i++
    ) {
      thisFromVue.$store.getters.selectedRepeat.elementIds.forEach(
        (elementId: string) => {
          const el = thisFromVue.$store.getters.canvas.select(
            "[sa-data-id='" + elementId + "']"
          );

          const parentEl = el.parent();

          parentEl.children().forEach((child: Snap.Element) => {
            // don't clone #text elements or elements that have a class of repeat-clone
            // cloning elements with a class of repeat-clone was causing duplicate clones
            if (child.type !== "#text" && !child.hasClass("repeat-clone")) {
              const clone: Snap.Element = child.clone();
              const id = uuidv4();
              clone.attr({ "sa-data-id": id });
              clone.addClass("repeat-clone");
              thisFromVue.$store.commit("addSelectedRepeatClonedElementId", id);

              clone.attr({ style: "opacity: 0.4" });
              const t = clone.transform().localMatrix;

              t.translate(
                0,
                parseFloat(thisFromVue.$store.getters.selectedRepeat.offset) *
                  (i + 1)
              );
              clone.transform(t.toTransformString());
            }
          });
        }
      );
    }
  }
}
