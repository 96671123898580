






























import Vue from "vue";

import $ from "jquery";

import ChangeBillingOwnerModal from "../modals/ChangeBillingOwnerModal.vue";
import {} from "../types";

export default Vue.extend({
  props: {
    companyId: {
      type: Number,
      required: true,
    },
  },
  components: { ChangeBillingOwnerModal },
  data: function () {
    return {
      billingOwner: {} as {
        id: number;
        firstName: string;
        lastName: string;
        image: string;
        email: string;
      },
    };
  },
  mounted: function () {
    this.fetchUserDetails();
  },
  methods: {
    isBillingOwner: function () {
      return (
        this.billingOwner.id === Number(this.$store.state.session.userId) ||
        this.$store.state.session.isSuperUser
      );
    },
    fetchUserDetails: function () {
      const query = JSON.stringify({
        query: `
          query getBillingOwnerDetails {
            company(id:${this.companyId}) {
              billingOwner {
                userId
                firstName
                lastName
                image
                email
              }
            }
          }
        `,
      });

      fetch("/graphql/", {
        method: "POST",
        body: query,
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
        },
      })
        .then((data) => data.json())
        .then((result) => {
          if (result.errors) {
            alert(result.errors[0].message);
          } else {
            this.billingOwner = {
              id: Number(result.data.company.billingOwner.userId),
              firstName: result.data.company.billingOwner.firstName,
              lastName: result.data.company.billingOwner.lastName,
              image: result.data.company.billingOwner.image,
              email: result.data.company.billingOwner.email,
            };
          }
        });
    },

    changeBillingOwner: function () {
      $("#change-billing-owner-modal").modal({ show: true });
    },
    calcTileHeight: function (): string {
      const rowHeight = $("#billingOwnerTile").closest("div.row").height();
      const billingTileHeight = $("#billingOwnerTile").height();
      let retVal = "0px";
      if (
        typeof rowHeight !== "undefined" &&
        typeof billingTileHeight !== "undefined"
      ) {
        if (rowHeight > billingTileHeight) {
          retVal = rowHeight - 20 + "px";
        } else {
          retVal = billingTileHeight + "px";
        }
      }
      return retVal;
    },
  },
  computed: {},
  watch: {
    subscription: function () {
      this.fetchUserDetails();
    },
  },
});
